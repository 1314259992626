import moment from 'moment';

const data = {
  Hoy: {
    ranges: [
      new Date(moment().startOf('day').format()),
      new Date(moment().endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'day',
      amount: 0,
    },
  },
  Ayer: {
    ranges: [
      new Date(moment().subtract(1, 'day').startOf('day')),
      new Date(moment().subtract(1, 'day').endOf('day')),
    ],
    ranged: {
      unitOfTime: 'day',
      amount: 1,
    },
  },
  'Últimos 7 días': {
    ranges: [
      new Date(moment().subtract(7, 'days').startOf('day')),
      new Date(moment().startOf('day').endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'day',
      amount: 7,
    },
  },
  'Últimos 30 días': {
    ranges: [
      new Date(moment().subtract(30, 'days').startOf('day')),
      new Date(moment().endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'day',
      amount: 30,
    },
  },
  'Últimos 90 días': {
    ranges: [
      new Date(moment().subtract(90, 'days').startOf('day')),
      new Date(moment().endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'day',
      amount: 90,
    },
  },
  'Año pasado': {
    ranges: [
      new Date(moment().subtract(1, 'year').startOf('year')),
      new Date(moment().subtract(1, 'year').endOf('yearF')),
    ],
    ranged: {
      unitOfTime: 'year',
      amount: 1,
    },
  },
  'Este mes': {
    ranges: [
      new Date(moment().startOf('month').startOf('day').format()),
      new Date(moment().endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'month',
      amount: 0,
    },
  },
  'Mes pasado': {
    ranges: [
      new Date(moment().subtract(1, 'month').startOf('month')),
      new Date(moment().subtract(1, 'month').endOf('month')),
    ],
    ranged: {
      unitOfTime: 'month',
      amount: 1,
    },
  },
  'Año hasta la fecha': {
    ranges: [
      new Date(moment().startOf('year').startOf('day')),
      new Date(moment().endOf('day').format()),
    ],
    ranged: {
      unitOfTime: 'year',
      amount: 0,
    },
  },
};

export const localeData = {
  direction: 'ltr',
  format: 'mm/dd/yyyy',
  separator: ' - ',
  applyLabel: 'Aplicar',
  cancelLabel: 'Cancelar',
  weekLabel: 'S',
  customRangeLabel: 'Rango personalizado',
  daysOfWeek: ['Lu', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'],
  monthNames: [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
  firstDay: 1,
};

export default data;
