<template>
  <div>
    <date-range-picker
      ref="picker"
      v-model="dateOnLoad"
      :locale-data="localeData"
      :ranges="rangesDatePicker"
      class="date-range-picker"
      @finish-selection="finishSelect()"
      @update="changeDates($event)"
    >
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="selectRange(range, name, ranges)"
            >
              {{ name }}
            </li>
          </ul>
        </div>
      </template>
      <template #input="picker">
        <span v-if="!isRanged" class="select-date-calendary">
          <i class="far fa-calendar-alt"></i>
          {{ getDatePickerFormat(picker.startDate) }} -
          {{ getDatePickerFormat(picker.endDate) }}
        </span>

        <span v-else class="select-date-calendary">
          <i class="far fa-calendar-alt"></i>
          {{ rangedName }}
        </span>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import moment from 'moment';
import rangesDatePicker, { localeData } from '@/util/rangeDatePicker';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

moment.locale('es-mx');

export default {
  name: 'DatePicker',
  components: { DateRangePicker },
  emits: ['change-date'],
  data() {
    return {
      rangesDatePicker,
      localeData,
      dateOnLoad: {
        startDate: moment().startOf('month').format(),
        endDate: moment().format(),
      },
      ranged: 'last',
      rangedName: 'Este mes',
      isRanged: true,
    };
  },
  methods: {
    selectRange(range, name, ranges) {
      this.isRanged = true;
      this.rangedName = name;
      this.ranged = range.ranged;
      ranges.clickRange(range.ranges);
    },
    changeDates(data) {
      this.$emit('change-date', {
        ...data,
        ranged: this.ranged,
      });
    },
    getDatePickerFormat(item) {
      return moment(item).format('ll');
    },
    finishSelect() {
      console.log;
      this.isRanged = false;
    },
  },
};
</script>

<style>
.select-date-calendary {
  font-size: 14px !important;
}
.date-range-picker > .form-control {
  height: 45px !important;
}
</style>
